import { makeStyles } from "@material-ui/core/styles";

const weatherStyles = makeStyles((theme) => ({
  "@media screen and (max-width: 750px)": {
    container: {
      paddingTop: "0.1rem !important",
      margin: "1.8rem 0.1rem 0rem 0.1rem !important",
    },
    divForm: {
      width: "100%",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 1rem)",
  },
  container: ({ scrollbarWidth }) => ({
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingTop: "0.2rem",
    paddingRight: `${scrollbarWidth}px`,
    margin: "2.5rem 1rem 0rem 1rem", // margintop 3.5rem avoid overlapping with the menu bar
    boxSizing: "border-box",
  }),
  divForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "50rem",
  },
  divActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "0.2rem",
    // margin: "0 0.5rem 0.5rem 0.5rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divHistory: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "0.5rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divResults: {
    // minHeight: "50vh",
    // minWidth: "80vh",
    width: "100%",
    marginTop: "0.2rem",
    marginBottom: "0.2rem",
    justifyContent: "center",
  },
  button: {
    backgroundColor: `${theme.palette.secondary.light} !important`, //or #159895
    color: `${theme.palette.common.white} !important`, //#FFFFFF
    height: "2rem",
    width: "5rem",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main} !important`, //#0f6d6a
    },
  },
}));

export default weatherStyles;
