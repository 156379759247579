import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const SearchHistoryContext = createContext();

export const useSearchHistory = () => {
  return useContext(SearchHistoryContext);
};

export const useWeatherData = () => {
  return useContext(SearchHistoryContext);
};

SearchHistoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function SearchHistoryProvider({ children }) {
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [weatherData, setWeatherData] = useState(null);

  return (
    <SearchHistoryContext.Provider
      value={{
        selectedHistoryItem,
        setSelectedHistoryItem,
        weatherData,
        setWeatherData,
      }}
    >
      {children}
    </SearchHistoryContext.Provider>
  );
}
