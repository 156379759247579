import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid from "@mui/material/Grid";

import WeatherCard from "../components/WeatherCard";
import weaResultStyles from "../styles/WeatherResult.styles";

WeatherResult.propTypes = {
  weatherRes: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      date: PropTypes.object, //string
      temp_min: PropTypes.number.isRequired,
      temp_max: PropTypes.number.isRequired,
      weather_icon_day: PropTypes.string.isRequired,
      weather_text_day: PropTypes.string.isRequired,
      weather_icon_night: PropTypes.string.isRequired,
      weather_text_night: PropTypes.string.isRequired,
      prep: PropTypes.number,
      uv: PropTypes.number,
      cloud: PropTypes.number,
      sunrise: PropTypes.string.isRequired,
      sunset: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

function WeatherResult({ weatherRes }) {
  const classes = weaResultStyles();
  // calculate global min and max temperature for all days for bar chart scale
  const global_temp = () => {
    let minTemp = weatherRes[0].temp_min;
    let maxTemp = weatherRes[0].temp_max;
    weatherRes.forEach((weather) => {
      if (weather.temp_min < minTemp) {
        minTemp = weather.temp_min;
      }
      if (weather.temp_max > maxTemp) {
        maxTemp = weather.temp_max;
      }
    });
    return [minTemp, maxTemp];
  };

  // desktop view settings by default (show arrows)
  const initialIsDesktop = window.innerWidth > 750;
  const [isDesktop, setIsDesktop] = useState(initialIsDesktop);
  const [showArrows, setShowArrows] = useState(initialIsDesktop);
  const [visibleCardIndex, setVisibleCardIndex] = useState(0);
  const [maxVisibleCards, setMaxVisibleCards] = useState(
    initialIsDesktop ? calculateVisibleCards(window.innerWidth) : 30, //set a large number for mobile device
  );
  const hasPreviousCards = visibleCardIndex > 0;
  const hasNextCards = weatherRes.length > visibleCardIndex + maxVisibleCards;

  function calculateVisibleCards(windowWidth) {
    // only for desktop version
    const cardWidth = 120; //7.5rem
    const cardMargin = 4; // 0.5rem
    const iconbutton = 40; //34px and consider scrollbar width
    const totalCardWidth = cardWidth + cardMargin;
    return Math.floor((windowWidth - iconbutton * 2) / totalCardWidth);
  }

  useEffect(() => {
    const checkIfDesktop = () => {
      // console.log(window.innerWidth);
      setIsDesktop(window.innerWidth > 750);
    };
    // Initial check
    checkIfDesktop();
    // Event listener for window resize
    window.addEventListener("resize", checkIfDesktop);
    // console.log(
    //   isDesktop,
    //   "maxvisiblecards",
    //   maxVisibleCards,
    //   weatherRes.length,
    //   showArrows,
    // );
    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", checkIfDesktop);
    };
  }, []);

  useEffect(() => {
    // console.log(
    //   isDesktop,
    //   "maxvisiblecards",
    //   maxVisibleCards,
    //   weatherRes.length,
    //   showArrows,
    // );
    // hide arrows if all cards are visible
    if (maxVisibleCards >= weatherRes.length) {
      setShowArrows(false);
      setVisibleCardIndex(0);
    } else {
      setShowArrows(true);
    }
  }, [maxVisibleCards, weatherRes.length]);

  useEffect(() => {
    if (isDesktop) {
      setShowArrows(true);
      setMaxVisibleCards(calculateVisibleCards(window.innerWidth));
    } else {
      setShowArrows(false);
      setMaxVisibleCards(30);
    }
  }, [isDesktop]);

  const handlePrevClick = () => {
    if (hasPreviousCards) {
      setVisibleCardIndex(visibleCardIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (hasNextCards) {
      setVisibleCardIndex(visibleCardIndex + 1);
    }
  };

  return (
    // <Box className={classes.root}>
    <Box className={classes.container}>
      {weatherRes.length > 0 && showArrows && (
        <IconButton
          size="small"
          disabled={!hasPreviousCards}
          onClick={handlePrevClick}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <Grid
        className={classes.grid}
        container
        alignItems="stretch"

        // spacing={1}
      >
        {weatherRes
          .slice(visibleCardIndex, visibleCardIndex + maxVisibleCards)
          .map((weather, index) => (
            <WeatherCard
              weather={weather}
              key={index}
              global_temp={global_temp()}
            />
          ))}
      </Grid>
      {weatherRes.length > 0 && showArrows && (
        <IconButton
          size="small"
          disabled={!hasNextCards}
          onClick={handleNextClick}
        >
          <ArrowForwardIcon />
        </IconButton>
      )}
    </Box>
    // </Box>
  );
}

export default WeatherResult;
