import { createTheme } from "@material-ui/core/styles";
// import { zhCN } from "@mui/x-date-pickers/locales";
// import { zhCN as coreZhCN } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#1A5F7A",
      },
      secondary: {
        main: "#0f6d6a",
      },
      tertiary: {
        main: "#159895",
      },
      common: {
        white: "#FFFFFF",
        whiteSmoke: "#F1F1F1",
        red: "red",
      },
    },

    // MuiTypography: {
    //   smallText: {
    //     fontSize: "0.75rem", // Define the custom font size
    //     fontWeight: 400,
    //     margin: 0,
    //     lineHeight: 1.43,
    //     letterSpacing: "0.01071em",
    //   },
    //   // body2: {
    //   //   fontSize: "0.75rem",
    //   // },
    // },
    // components: {
    //   MuiTypography: {
    //     styleOverrides: {
    //       body2: {
    //         fontSize: "0.75rem",
    //       },
    //     },
    //     defaultProps: {
    //       variantMapping: {
    //         // Map the new variant to render a <p> by default
    //         smallText: "p",
    //       },
    //     },
    //   },
    // },
  },
  // zhCN, // x-date-pickers translations
  // coreZhCN, // material-ui translations
);

export default theme;
