// src/components/Footer.js
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import icon from "../images/gonganbeian.png";
// import Divider from "@mui/material/Divider";

// const classes = {
const footerStyles = makeStyles(() => ({
  footer: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#f8f8f8",
    fontSize: "0.8rem",
    borderTop: "1px solid #e7e7e7",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  divRow: {
    display: "flex",
    alignItems: "center", // Vertically align the image and text
    justifyContent: "center",
  },
  text: {
    margin: "0.5rem 0",
    color: "inherit", // #666
    textDecoration: "none" /* This removes the underline */,
  },
  icon: {
    width: "0.9rem",
    height: "0.9rem",
  },
  "@media screen and (max-width: 750px)": {
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 0,
      padding: "0.5rem 0",
    },
    text: {
      margin: 0,
    },
  },
}));

const Footer = () => {
  const classes = footerStyles();
  return (
    <footer className={classes.footer}>
      {/* <Divider /> */}
      <div className={classes.content}>
        <p className={classes.text}>© 2024 MultiWeather.top 版权所有</p>
        <a
          className={classes.text}
          href="http://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          粤ICP备2024271892号
        </a>
        <div className={classes.divRow}>
          <img src={icon} alt="公安备案" className={classes.icon} />
          <a
            className={classes.text}
            href="https://beian.mps.gov.cn/#/query/webSearch"
            rel="noreferrer"
            target="_blank"
          >
            粤公网安备44011702000838
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
