import React from "react"; // { useState, useEffect }
import { BrowserRouter, Routes, Route } from "react-router-dom"; //Outlet
import { ThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";

import theme from "./styles/theme";
// import { useAuth } from "./authentication/firebase";
// import Home from "./pages/Home";
import Weather from "./pages/Weather";
// import About from "./pages/About";
// import Result from "./pages/Result";
// import Login from "./pages/Login";
// import Forgot from "./pages/Forgot";
// import Signup from "./pages/Signup";
// import Account from "./pages/Account";
// import History from "./pages/History";
// import LoadingSpinner from "./components/LoadingSpinner";
import { SearchHistoryProvider } from "./utils/SearchHistoryContext";
// import { AuthContextProvider } from "./authentication/firebase";

// const ProtectedRoute = () => {
//   const user = useAuth();

//   console.log("User authenticated", user);

//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (user !== undefined) {
//       setLoading(false);
//     }
//   }, [user]);
//   if (loading) {
//     return <LoadingSpinner />;
//   }
//   return user ? <Outlet /> : <Login />;
// };

function App() {
  const history = createBrowserHistory();

  return (
    <ThemeProvider theme={theme}>
      {/* <AuthContextProvider> */}
      <SearchHistoryProvider>
        <BrowserRouter history={history}>
          <Routes>
            <Route path="/" element={<Weather />} />
            {/* <Route path="/home" element={<Home />} />
              <Route path="/weather" element={<Weather />} />
              <Route path="/about" element={<About />} />
              <Route path="/result" element={<Result />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/signup" element={<Signup />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/account" element={<Account />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/history/" element={<History />} />
              </Route> */}
          </Routes>
        </BrowserRouter>
      </SearchHistoryProvider>
      {/* </AuthContextProvider> */}
    </ThemeProvider>
  );
}

export default App;
