import { makeStyles } from "@material-ui/core/styles";

const logoStyles = makeStyles(() => ({
  logoContainer: {
    display: "flex",
    alignItems: "left",
  },
  logoImage: {
    width: "11.67rem", //14
    height: "2.5rem", //3
    // cursor: "pointer",
  },

  "@media screen and (max-width: 750px)": {
    logoImage: {
      width: "7rem",
      height: "1.5rem",
    },
  },
}));

export default logoStyles;
