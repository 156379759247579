import React from "react"; //{ useContext, useState, useEffect }
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";

import menuStyles from "../styles/Menu.styles";
import Logo from "./Logo";
// import DropdownMenu from "./DropdownMenu";
// import { AuthContext } from "../authentication/firebase";

// const pages = ["Home", "Weather", "About", "Login", "Account"];
// const pages = [];

function MenuBar() {
  const classes = menuStyles();

  // const handleButtonClick = (page) => {
  //   window.location.href = `/${page.toLowerCase()}`;
  // };
  // const user = useContext(AuthContext);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setLoading(false);
  //   }
  // }, [user]);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container className={classes.container}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Logo className={classes.logo} />
          {/* <div className={classes.rightButtonGroup}>
            {loading ? (
              <div className={classes.button} />
            ) : (
              <>
                {pages.map((page) => {
                  if (page === "Login" && user) return null;
                  if (page === "Account" && !user) return null;
                  if (page === "Account" && user)
                    return <DropdownMenu key={`menu-${page}`} />;
                  return (
                    <Button
                      key={`button-${page}`}
                      className={classes.button}
                      onClick={() => handleButtonClick(page)}
                    >
                      {page}
                    </Button>
                  );
                })}
              </>
            )}
          </div> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MenuBar;
