const dateRegex = new RegExp("^\\d\\d\\d\\d-\\d\\d-\\d\\d");
// const config = require("../config.json");

function jsonDateReviver(key, value) {
  if (dateRegex.test(value)) return new Date(value);
  return value;
}

async function graphQLFetch(query, variables = {}) {
  try {
    const host = process.env.REACT_APP_API_HOST; // || config.HOST;
    const port = process.env.REACT_APP_API_PORT; // || config.PORT_SERVER;
    // nginx listen to port 80 for production, no need to specify port number
    // console.log("client, host:port", host, port);
    const address = port
      ? `http://${host}:${port}/graphql` // test, dev
      : `https://${host}/graphql`; // production
    // console.log("address", address);
    // const address = `http://${config.HOST}:${config.PORT_SERVER}/graphql`;
    const response = await fetch(address, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query, variables }),
    });
    const body = await response.text();
    const result = JSON.parse(body, jsonDateReviver);

    if (result.errors) {
      const error = result.errors[0];
      if (error.extensions.code === "BAD_USER_INPUT") {
        const details = error.extensions.exception.errors.join("\n ");
        alert(`${error.message}:\n ${details}`);
      } else {
        alert(`${error.extensions.code}: ${error.message}`);
      }
    }
    return result.data;
  } catch (e) {
    alert(`Error in sending data to server: ${e.message}`);
  }
}

export default graphQLFetch;
// export { graphQLFetch };
