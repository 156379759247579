import React from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js"; // CategoryScale (x), LinearScale (y)

import { ReactComponent as Sunrise } from "../images/sunrise.svg";
import { ReactComponent as Sunset } from "../images/sunset.svg";
import weaResultStyles from "../styles/WeatherResult.styles";

ChartJS.register(BarController, BarElement, CategoryScale, LinearScale);

WeatherCard.propTypes = {
  weather: PropTypes.shape({
    location: PropTypes.string.isRequired,
    // day: PropTypes.string.isRequired,
    date: PropTypes.object, //string
    temp_min: PropTypes.number.isRequired,
    temp_max: PropTypes.number.isRequired,
    weather_icon_day: PropTypes.string.isRequired,
    weather_text_day: PropTypes.string.isRequired,
    weather_icon_night: PropTypes.string.isRequired,
    weather_text_night: PropTypes.string.isRequired,
    prep: PropTypes.number,
    uv: PropTypes.number,
    cloud: PropTypes.number,
    sunrise: PropTypes.string.isRequired,
    sunset: PropTypes.string.isRequired,
  }).isRequired,
  global_temp: PropTypes.arrayOf(PropTypes.number).isRequired,
};

function WeatherCard({ weather, global_temp }) {
  const settings = {
    language: "zh_hans",
  };

  const classes = weaResultStyles();
  const prep = weather.prep ? `${weather.prep.toFixed(0)}mm` : "--";
  const cloud = weather.cloud ? `${weather.cloud.toFixed(0)}%` : "";
  let uv = weather.uv;
  if (uv === null) {
    uv = "--";
  } else {
    let uv_level = "";
    if (uv >= 0 && uv <= 2) {
      uv_level = "最弱";
    } else if (uv >= 3 && uv <= 4) {
      uv_level = "弱";
    } else if (uv >= 5 && uv <= 6) {
      uv_level = "中等";
    } else if (uv >= 7 && uv <= 9) {
      uv_level = "强";
    } else if (uv >= 10) {
      uv_level = "很强";
    }
    // uv = `${uv} ${uvLevel}`;
    uv = `紫外线${uv} ${uv_level}`;
  }
  const celsius = "\u00B0C";
  const temp_min = `${weather.temp_min.toFixed(0)}${celsius}`;
  const temp_max = `${weather.temp_max.toFixed(0)}${celsius}`;

  // Data for the temperature bar chart
  const bar_data = {
    labels: ["Temperature"],
    datasets: [
      {
        indexAxis: "x", // vertical bar chart
        label: "Temperature",
        data: [[weather.temp_min, weather.temp_max]],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        barThickness: 100,
        borderSkipped: true,
        // borderColor: "rgba(54, 162, 235, 1)",
        // borderWidth: 1,
      },
    ],
  };
  // Configuration options for the temperature bar chart
  const bar_options = {
    responsive: false,
    scales: {
      y: {
        display: false,
        min: global_temp[0],
        max: global_temp[1],
        beginAtZero: false,
        border: {
          display: false,
        },
      },
      x: {
        display: false,
        border: {
          display: false,
        },
      },
    },
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardContentDiv}>
          <Typography
            variant="body2" //subtitle1
            color="textSecondary"
          >
            {new Intl.DateTimeFormat(settings.language.replace("_", "-"), {
              weekday: "short",
            }).format(new Date(weather.date))}
          </Typography>
          <Typography variant="body2" className={classes.textSubtitle}>
            {
              new Intl.DateTimeFormat(settings.language.replace("_", "-"), {
                dateStyle: "medium",
              })
                .format(new Date(weather.date))
                .split(settings.language.startsWith("zh") ? "年" : ",")[
                settings.language.startsWith("zh") ? 1 : 0
              ]
            }
          </Typography>
          <img
            className={classes.weatherImage}
            src={`/qweather-icons/icons/${weather.weather_icon_day}.svg`}
            alt={weather.weather_text_day}
          />
          <Typography variant="body2" noWrap={true}>
            日间 {weather.weather_text_day}
          </Typography>
          <Typography
            variant="body2" //subtitle1
            className={classes.textSubtitle}
          >
            {temp_max}
          </Typography>
          <Box className={classes.divChart}>
            <Chart
              className={classes.chart}
              type="bar"
              data={bar_data}
              options={bar_options}
            />
          </Box>
          <Typography
            variant="body2" //subtitle1
            className={classes.textSubtitle}
          >
            {temp_min}
          </Typography>
          <img
            className={classes.weatherImage}
            src={`/qweather-icons/icons/${weather.weather_icon_night}-fill.svg`}
            alt={weather.weather_text_day}
          />
          <Typography variant="body2" noWrap={true}>
            夜间 {weather.weather_text_night}
          </Typography>

          {/* <Box className={classes.divSupInfo}>
            <Box className={classes.divPrep}>
              <Typography variant="caption" noWrap={true}>
                降水{prep}
              </Typography>
              <img
                className={classes.iconImage}
                src={`/qweather-icons/icons/399.svg`}
              />
            </Box>
            <Box className={classes.divCloud}>
              <Typography variant="caption" noWrap={true}>
                云量{cloud}
              </Typography>
              <img
                className={classes.iconImage}
                src={`/qweather-icons/icons/104.svg`}
              />
            </Box>
          </Box> */}
          <Box className={classes.divSupInfo}>
            <Typography variant="caption">降水{prep}</Typography>
            <Typography variant="caption">云量{cloud}</Typography>
            <Typography variant="caption">{uv}</Typography>
          </Box>
          <Box className={classes.divSun}>
            <Sunrise style={{ height: "90%" }} />
            <Typography variant="body2">{weather.sunrise}</Typography>
          </Box>
          <Box className={classes.divSun}>
            <Sunset style={{ height: "90%" }} />
            <Typography variant="body2">{weather.sunset}</Typography>
          </Box>
          <Typography
            variant="body2" //subtitle1
            className={classes.textCity}
            noWrap={true}
          >
            {weather.location}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default WeatherCard;
