import { makeStyles } from "@material-ui/core/styles";

const menuStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
  },
  container: {
    // maxWidth: "xl",
    width: "xs",
    // position: "fixed",
  },
  toolbar: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px dashed",
    borderBottomColor: theme.palette.secondary.light,
    display: "flex", //flex
    flexDirection: "row",
    position: "fixed !important",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "2.5rem !important", //3.5
    zIndex: 1000,
    boxSizing: "border-box",
  },
  "@media screen and (max-width: 750px)": {
    toolbar: {
      minHeight: "1.8rem !important",
      justifyContent: "center",
    },
  },
  // rightButtonGroup: {
  //   marinLeft: "auto",
  //   display: "flex",
  //   justifyContent: "flex-end",
  // },
  // button: {
  //   color: theme.palette.primary.main,
  //   display: "block",
  //   fontSize: "1rem",
  //   margin: theme.spacing(0, 2),
  //   [theme.breakpoints.down("sm")]: {
  //     margin: theme.spacing(0, 1),
  //     fontSize: "0.75rem",
  //     padding: theme.spacing(1),
  //   },
  // },
  // dropdown: {
  //   position: "relative",
  //   display: "inline-block",
  //   [theme.breakpoints.down("sm")]: {
  //     margin: theme.spacing(0, 1),
  //   },
  // },
  // dropdownMenuItem: {
  //   color: theme.palette.primary.main,
  //   textTransform: "uppercase",
  //   padding: theme.spacing(1),
  //   "&:hover": {
  //     backgroundColor: theme.palette.primary.whiteSmoke,
  //   },
  // },
}));

export default menuStyles;
