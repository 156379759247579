import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { zhCN } from "@mui/x-date-pickers/locales";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

// import { getLocalDateStr } from "../utils/helpers";
import queryStyles from "../styles/Query.styles";

DateRangePicker.propTypes = {
  schedule: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  onUpdateSchedule: PropTypes.func.isRequired,
  onUpdateScheduleRef: PropTypes.func.isRequired,
  error: PropTypes.shape({
    location: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

function DateRangePicker({
  schedule,
  onUpdateSchedule,
  onUpdateScheduleRef,
  error,
}) {
  // const config = require("../config.json");
  const classes = queryStyles();
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDateHelperText, setStartDateHelperText] = useState("");
  const [endDateHelperText, setEndDateHelperText] = useState("");
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  // update reference to notify ancestor component Weather
  useEffect(() => {
    onUpdateScheduleRef("start", startDateRef);
    onUpdateScheduleRef("end", endDateRef);
  }, []);

  // update error messages for form validation
  useEffect(() => {
    if (error) {
      setStartDateError(!!error.start);
      setStartDateHelperText(error.start || "");
      setEndDateError(!!error.end);
      setEndDateHelperText(error.end || "");
    }
  }, [error]);

  const today = new Date();
  const today_dayjs = dayjs(today);
  // const today_str = getLocalDateStr(new Date());

  const maxDate = new Date();
  maxDate.setDate(
    maxDate.getDate() + parseInt(process.env.REACT_APP_PREDICT_PERIOD) - 1,
  ); //weather prediction period!!!
  const maxDate_dayjs = dayjs(maxDate);
  // const maxDate_str = getLocalDateStr(maxDate);

  // reset the selectable date range for end date input
  function handleStartDateChange(value) {
    const startDate = value.format("YYYY-MM-DD"); // e.target.value.toString();
    const newSchedule = schedule;
    newSchedule.start = startDate;
    onUpdateSchedule(newSchedule);
    setStartDateError(false);
    setStartDateHelperText("");
    if (new Date(startDate) > new Date(schedule.end)) {
      setEndDateError(true);
    }
    endDateRef.current.focus();
  }

  //check for invalid end date input
  function handleEndDateChange(value) {
    const endDate = value.format("YYYY-MM-DD"); //e.target.value.toString();
    if (new Date(endDate) >= new Date(schedule.start)) {
      setEndDateError(false);
      setEndDateHelperText("");
      const newSchedule = schedule;
      newSchedule.end = endDate;
      onUpdateSchedule(newSchedule);
    } else {
      setEndDateError(true);
      setEndDateHelperText("无效结束日期"); //Invalid end date
    }
  }

  return (
    <Box component="span" className={classes.divDateRange}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={"zh-cn"}
        localeText={
          zhCN.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DatePicker
          inputRef={startDateRef}
          views={["month", "day"]}
          format="MM-DD"
          value={dayjs(schedule.start)}
          onChange={handleStartDateChange}
          minDate={today_dayjs}
          maxDate={maxDate_dayjs}
          closeOnSelect={true}
          slots={{
            textField: (props) => (
              <TextField
                {...props}
                required
                className={classes.divDatepickerLeftMUIX}
                size="small"
                variant="standard"
                error={startDateError}
                helperText={startDateHelperText}
                InputLabelProps={{ shrink: true }}
              />
            ),
            openPickerButton: (props) => <IconButton {...props} size="small" />,
          }}
        />
        至
        <DatePicker
          inputRef={endDateRef}
          views={["month", "day"]}
          format="MM-DD"
          value={dayjs(schedule.end)}
          onChange={handleEndDateChange}
          minDate={schedule.start ? dayjs(schedule.start) : today_dayjs}
          maxDate={maxDate_dayjs}
          closeOnSelect={true}
          slots={{
            textField: (props) => (
              <TextField
                {...props}
                required
                className={classes.divDatepickerRightMUIX}
                size="small"
                variant="standard"
                error={endDateError}
                helperText={endDateHelperText}
                InputLabelProps={{ shrink: true }}
              />
            ),
            openPickerButton: (props) => <IconButton {...props} size="small" />,
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default DateRangePicker;
