import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Typography } from "@mui/material";

import LocationSearch from "./LocationSearch";
// import DateRangePicker from "./DateRangePicker";
import DateRangePicker from "./DateRangePicker_muix";
import queryStyles from "../styles/Query.styles";

QueryForm.propTypes = {
  schedule: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onUpdateSchedule: PropTypes.func.isRequired,
  onRemoveSchedule: PropTypes.func.isRequired,
  schedulelength: PropTypes.number.isRequired,
  onUpdateScheduleRef: PropTypes.func.isRequired,
  error: PropTypes.object,
  autocompleteKey: PropTypes.number,
  removedScheduleIndex: PropTypes.number,
};

function QueryForm({
  schedule,
  index,
  onUpdateSchedule,
  onRemoveSchedule,
  schedulelength,
  onUpdateScheduleRef,
  error,
  autocompleteKey,
  removedScheduleIndex,
}) {
  const classes = queryStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.divHeader}>
        <Box className={classes.divSubtitle}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            行程 {index + 1}
          </Typography>
        </Box>
        {schedulelength > 1 && (
          <IconButton onClick={onRemoveSchedule} size="small">
            <RemoveCircleOutlineIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
      <Box className={classes.divQuery}>
        <LocationSearch
          index={index}
          schedule={schedule}
          onUpdateSchedule={onUpdateSchedule}
          onUpdateScheduleRef={onUpdateScheduleRef}
          error={error}
          autocompleteKey={autocompleteKey}
          removedScheduleIndex={removedScheduleIndex}
        />
        <DateRangePicker
          schedule={schedule}
          onUpdateSchedule={onUpdateSchedule}
          onUpdateScheduleRef={onUpdateScheduleRef}
          error={error}
        />
      </Box>
    </Box>
  );
}

export default QueryForm;
