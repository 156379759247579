import { makeStyles } from "@material-ui/core/styles";

const QueryStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  divHeader: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    alignItems: "flex-start",
    // marginBottom: "1rem",
    minHeight: "28px",
  },
  divSubtitle: {
    marginRight: "1rem",
  },
  subtitle: {
    color: theme.palette.primary.main,
  },
  divQuery: {
    display: "flex",
    // flex: "1 1 auto", //
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "0.35rem",
    width: "100%",
  },
  divLocation: {
    display: "flex",
    flex: "1 1 auto",
    alignItems: "flex-start",
    marginRight: "0.5rem",
    width: 0,
  },
  divAutocomplete: {
    width: "100%",
    // flex: "1 1 auto", //
  },
  divDateRange: {
    display: "flex",
    // flex: "1 1 auto", //
    alignItems: "flex-start",
  },
  divDatepickerLeft: {
    width: "6.8rem",
    marginRight: "0.5rem !important",
  },
  divDatepickerRight: {
    width: "6.8rem",
    marginLeft: "0.5rem !important",
  },
  divDatepickerLeftMUIX: {
    width: "5rem",
    marginRight: "0.5rem !important",
  },
  divDatepickerRightMUIX: {
    width: "5rem",
    marginLeft: "0.5rem !important",
  },
  // non-desktop version if the media query @media (pointer: fine) mismatches
  "@media screen and (max-width: 750px) and (pointer: coarse)": {
    divDatepickerLeftMUIX: {
      width: "3rem",
    },
    divDatepickerRightMUIX: {
      width: "3rem",
    },
  },
}));

export default QueryStyles;
