import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  // useContext,
} from "react";
import { v4 as uuidv4 } from "uuid";
// import { useNavigate } from "react-router-dom";
// import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import MenuBar from "../components/MenuBar";
import QueryForm from "../components/QueryForm";
import WeatherResult from "../components/WeatherResult";
import SearchHistory from "../components/SearchHistory";
import LoadingSpinner from "../components/LoadingSpinner";
import Footer from "../components/footer";
import graphQLFetch from "../utils/graphQLFetch";
// import { AuthContext } from "../authentication/firebase";
import {
  copyObj,
  areSchedulesEqual,
  getScrollbarWidth,
} from "../utils/helpers"; //getDateStr
import {
  useSearchHistory,
  // useWeatherData,
} from "../utils/SearchHistoryContext";
import weatherStyles from "../styles/Weather.styles";

function Weather() {
  // initialization
  // const config = require("../config.json");
  const platform = 1; // 1 for web, 2 for 小程序, 3 for Android, 4 for iOS
  const [searchHis, setSearchHis] = useState(loadSearchHis());

  // const navigate = useNavigate();  // for navigation to packaging tips page
  // const user = useContext(AuthContext);
  // let user_id = user ? user.uid : undefined;
  const user_id = getLocalUserId();
  const [scrollbarWidth, setScrollbarWidth] = useState(getScrollbarWidth());
  const [, setScheduleRefs] = useState([]); // manage reference for location, start date, end date input of each schedule
  const [weatherRes, setWeatherRes] = useState([]); // to be passed to WeatherResult component
  const [isLoading, setIsLoading] = useState(false); // render loading to wait asynchronous functions
  const [autocompleteKey, setAutocompleteKey] = useState([0]); // to be passed to LocationSearch component
  const [removedScheduleIndex, setRemovedScheduleIndex] = useState(-1); // to notify LocationSearch to clear autocomplete options
  const [schedules, setSchedules] = useState([
    {
      location: "",
      locationID: "",
      state: null,
      country: null,
      lon: null,
      lat: null,
      start: "",
      end: "",
    },
  ]);
  const [errors, setErrors] = useState([{ location: "", start: "", end: "" }]);
  const schedulesRef = useRef(schedules); // set reference for state 'schedules', for handleSubmit to get the up-to-date schedules
  const { selectedHistoryItem, setSelectedHistoryItem } = useSearchHistory();
  // const { setWeatherData } = useWeatherData(); // passing data to packaging tips page

  const classes = weatherStyles({ scrollbarWidth }); //styles

  /** search history management **/
  // load user's search history
  function loadSearchHis() {
    return (
      JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY),
      ) || []
    );
  }

  // get user id if not logged in
  function getLocalUserId() {
    if (searchHis.length === 0) {
      // console.log("generate user id");
      return generateUserId();
    } else {
      return searchHis[0].user_id;
    }
  }

  // generate user id
  function generateUserId() {
    // random user id with UUID library;
    return uuidv4();
  }

  // update local storage with new search history
  function updateSearchHistory(user_id, new_schedules) {
    setSearchHis((prevSearchHis) => {
      let new_searchHis = copyObj(prevSearchHis);
      // !! compare schedules by value, not by reference ===
      const exist_index = new_searchHis.findIndex(
        (record) =>
          record.user_id === user_id &&
          areSchedulesEqual(record.schedules, new_schedules),
      );
      const cur_time = new Date().toISOString();
      if (exist_index !== -1) {
        // update existing record
        new_searchHis[exist_index].update_time = cur_time;
        // sort by update time in descending order
        new_searchHis.sort(
          (a, b) => new Date(b.update_time) - new Date(a.update_time),
        );
      } else {
        // add new record to the beginning
        new_searchHis.unshift({
          user_id: user_id,
          schedules: copyObj(new_schedules),
          create_time: cur_time,
          update_time: cur_time,
        });
        // limit the size of search history
        if (new_searchHis.length > process.env.REACT_APP_MAX_HIST) {
          new_searchHis = new_searchHis.slice(
            0,
            process.env.REACT_APP_MAX_HIST,
          );
        }
      }

      // update search history in local storage
      // console.log("searchhis", searchHis);
      localStorage.setItem(
        process.env.REACT_APP_LOCAL_STORAGE_KEY,
        JSON.stringify(searchHis),
      );
      return new_searchHis;
    });
  }

  // click on "add another schedule" button
  const handleAddSchedule = useCallback(() => {
    if (schedules.length < process.env.REACT_APP_MAX_SCHEDULES) {
      setSchedules([
        ...schedules,
        {
          location: "",
          locationID: "",
          state: null,
          country: null,
          lon: null,
          lat: null,
          start: "",
          end: "",
        },
      ]);
      setErrors([...errors, { location: "", start: "", end: "" }]);
      setAutocompleteKey([...autocompleteKey, 0]);
    }
  }, [schedules, errors, autocompleteKey]);

  // click on "-" button
  const handleRemoveSchedule = (index) => {
    if (schedules.length > 1) {
      setSchedules(schedules.filter((_, i) => i !== index));
      setErrors(() => {
        // console.log("olderrors", errors);
        const newErrors = errors.filter((_, i) => i !== index);
        // console.log("newERRORS", newErrors);
        return newErrors;
      });
      const newKeys = autocompleteKey.filter((_, i) => i !== index);
      for (let i = index; i < schedules.length; i++) {
        newKeys[i] += 1;
      }
      setAutocompleteKey(newKeys);
      setRemovedScheduleIndex(index); // to notify child components to update autocomplete options
    }
  };

  // update state schedules
  const handleScheduleChange = (index, newSchedule) => {
    setSchedules((prevState) => {
      const newSchedules = [...prevState];
      newSchedules[index] = newSchedule;
      return newSchedules;
    });
  };

  // to be passed to child componenets to update input component's reference
  const handleScheduleRefChange = (index, field, ref) => {
    setScheduleRefs((prevState) => {
      const newRefs = [...prevState];
      if (!newRefs[index]) {
        newRefs[index] = {};
      }
      newRefs[index][field] = ref;
      return newRefs;
    });
  };

  // update error message for specific field of a certain schedule
  const handleErrorMessageChange = useCallback((index, field, ref) => {
    setErrors((prevState) => {
      const newErrors = [...prevState];
      newErrors[index][field] = ref;
      return newErrors;
    });
  }, []);

  // check if start date and end date are not null
  const checkDateRequired = useCallback(
    (index, field, value) => {
      if (!value) {
        const errorMessage = "请输入"; //`Empty ${field} date`
        handleErrorMessageChange(index, field, errorMessage);
        return false;
      }
      return true;
    },
    [handleErrorMessageChange],
  );

  // search form validation
  const formValidation = useCallback(
    (schedules) => {
      let flag = true;
      let valid_date = true;
      // check each schedule individually
      for (let i = 0; i < schedules.length; i++) {
        const schedule = schedules[i];
        // if location is valid (selected from options)
        if (schedule.lat === null || schedule.lon === null) {
          const errorMessage = "无效地点"; //Invalid location
          handleErrorMessageChange(i, "location", errorMessage);
          flag = false;
        }
        //if end date is equal to or greater than start date
        const start_required = checkDateRequired(i, "start", schedule.start);
        const end_required = checkDateRequired(i, "end", schedule.end);
        if (start_required && end_required) {
          if (new Date(schedule.start) > new Date(schedule.end)) {
            const errorMessage = "无效日期"; //Invalid end date
            handleErrorMessageChange(i, "end", errorMessage);
            flag = false;
          }
        } else {
          valid_date = false;
          flag = false;
        }
      }
      // if empty date is found, will not check further
      if (!valid_date) {
        return flag;
      }
      // if date range of any two schedules overlap
      for (let i = 0; i < schedules.length; i++) {
        for (let j = i + 1; j < schedules.length; j++) {
          let scheduleA;
          let scheduleB;
          let A;
          let B;
          if (schedules[i].start < schedules[j].start) {
            scheduleA = schedules[i];
            scheduleB = schedules[j];
            A = i;
            B = j;
          } else {
            scheduleA = schedules[j];
            scheduleB = schedules[i];
            A = j;
            B = i;
          }
          const endDateA = new Date(scheduleA.end);
          const startDateB = new Date(scheduleB.start);
          if (startDateB < endDateA && startDateB < new Date(scheduleB.end)) {
            const errorMessage = `与行程${A + 1}冲突`; //`Overlap with Schedule ${A + 1}`
            handleErrorMessageChange(B, "start", errorMessage);
            flag = false;
          }
        }
      }
      return flag;
    },
    [handleErrorMessageChange, checkDateRequired],
  );

  // clear error messages
  const resetErrors = useCallback(() => {
    const n_schedules = schedulesRef.current.length;
    const newErrors = [];
    const obj = { location: "", start: "", end: "" };
    for (let i = 0; i < n_schedules; i++) {
      newErrors.push({ ...obj });
    }
    setErrors(newErrors);
  }, []);

  // call graphQL API to get weather data based on valid schedules
  const getWeatherRes = useCallback(async (inputSchedule) => {
    const source = "hefeng";
    const lng = "zh-hans"; //by default
    const unit = "C"; //celcius, 公制 by default
    const variables = { inputSchedule, lng, unit, source };
    const query = `
    query weather(
      $inputSchedule: [ScheduleInput!]!
      $lng: String, 
      $unit: String,
      $source: String!
    ) {
      listWeather(
        schedules: $inputSchedule
        lng:$lng, 
        unit:$unit,
        source:$source
      ) {
        location date temp_min temp_max
        weather_text_day weather_text_night weather_icon_day weather_icon_night
        prep uv cloud
        sunrise sunset
      }
    }`;
    setIsLoading(true);
    const data = await graphQLFetch(query, variables);
    if (data && data.listWeather) {
      setWeatherRes(data.listWeather);
      setIsLoading(false);
    }
  }, []);

  // call graphQL API to log search history into database
  async function addSearchHistory(user_id, schedules, platform) {
    const source = "hefeng";
    const variables = { user_id, schedules, platform, source };
    const query = `
      mutation addSearchHistory(
        $user_id: String!, 
        $schedules: [ScheduleInput!]!, 
        $platform: Int!,
        $source: String
      ) {
        addSearchHistory(
          user_id: $user_id, 
          schedules: $schedules, 
          platform: $platform,
          source: $source
        )
      }
        `;

    // const query = `
    //   mutation addSearchHistory($user_id: String!, $schedules: [ScheduleInput!]!, $source: Int!) {
    //     addSearchHistory(user_id: $user_id, schedules: $schedules, source: $source)
    //   }
    // `;
    // const variables = { user_id, schedules, source };
    const data = await graphQLFetch(query, variables);
    return data && data.addSearchHistory;
  }

  // click on "search" button
  const handleSubmit = useCallback(
    async (event) => {
      if (event) event.preventDefault();
      resetErrors();
      if (formValidation(schedulesRef.current)) {
        // console.log("valid schedule-form", user_id);
        await getWeatherRes(schedulesRef.current);
        //once logged in, get or generated user id
        if (user_id) {
          // update local storage
          updateSearchHistory(user_id, schedulesRef.current);
          // log search history into database
          await addSearchHistory(user_id, schedulesRef.current, platform);
        }
      } else {
        // console.log("invalid");
      }
    },
    [
      formValidation,
      resetErrors,
      getWeatherRes,
      user_id,
      schedulesRef,
      // updateSearchHistory,
      // selectedHistoryItem,
      // searchHis is forbidden, as it would triggers infinite re-rendering
    ],
  );

  // if directed from search History, render selected history item
  // only trigger search if all start dates are no soonner than today
  useEffect(() => {
    if (selectedHistoryItem && selectedHistoryItem.schedules[0].lat) {
      // deep copy to avoid datepicker value change making search history dates change
      const cur_schedules = copyObj(selectedHistoryItem.schedules);
      setSchedules(cur_schedules);
      cur_schedules.sort((a, b) => new Date(a.end) - new Date(b.end));
      // console.log("schedules sort", cur_schedules[0].start);
      if (
        new Date(cur_schedules[0].start).toISOString().split("T")[0] >=
        new Date().toISOString().split("T")[0]
      ) {
        setTimeout(() => {
          handleSubmit();
        }, 50);
      }
    }
  }, [selectedHistoryItem, handleSubmit, setSelectedHistoryItem]);

  useEffect(() => {
    setRemovedScheduleIndex(-1); //reset and wait for the next remove action
    schedulesRef.current = schedules; // update scheduleRef for handleSubmit() to use as input parameter
  }, [schedules]);

  // If the user is not logged in, generate or retrieve the local user ID
  // useEffect(() => {
  //   if (!user_id) {
  //     user_id = getLocalUserId();
  //     console.log("Local User ID:", user_id);
  //   }
  // }, [handleSubmit]); // after the initial render, and dependency update

  // recalculate scrollbar width if weather result is updated
  useEffect(() => {
    setScrollbarWidth(getScrollbarWidth());
    // console.log("scrollbarWidth on weathRes change", scrollbarWidth);
  }, [weatherRes]);

  // each time re-rendering the page
  useEffect(() => {
    // update layout for vertical scroll bar
    const handleScrollBar = () => {
      setScrollbarWidth(getScrollbarWidth());
    };
    // console.log("scrollbarWidth on re-render", scrollbarWidth);
    window.addEventListener("resize", handleScrollBar);

    // stop initializing if directed from History page, render selected history item and trigger search
    if (selectedHistoryItem && selectedHistoryItem.schedules[0].lat) {
      return;
    }
    return () => window.removeEventListener("resize", handleScrollBar);

    /* initialize a schedule to render the local weather forecast */
    // const start = new Date();
    // const end = new Date();
    // end.setDate(end.getDate() + 4);
    // const localSchedule = [
    //   {
    //     location: "Singapore",
    //     state: null,
    //     country: "Singapore",
    //     lon: 103.8,
    //     lat: 1.3,
    //     start: getDateStr(start),
    //     end: getDateStr(end),
    //   },
    // ];
    // const fetchData = async () => {
    //   await getWeatherRes(localSchedule);
    // };
    // fetchData();

    // clean up the event listener when the component is unmounted,
    // prevents potential memory leaks and ensures the removal when no longer needed
  }, []);

  return (
    <Box className={classes.root}>
      {isLoading && <LoadingSpinner />}
      <MenuBar />
      <Box className={classes.container}>
        <Box className={classes.divForm}>
          {schedules &&
            schedules.map((schedule, index) => {
              return (
                <QueryForm
                  key={index}
                  index={index}
                  schedule={schedule}
                  onUpdateScheduleRef={(field, value) =>
                    handleScheduleRefChange(index, field, value)
                  }
                  onUpdateSchedule={(newSchedule) =>
                    handleScheduleChange(index, newSchedule)
                  }
                  onRemoveSchedule={() => handleRemoveSchedule(index)}
                  schedulelength={schedules.length}
                  error={errors[index]}
                  autocompleteKey={autocompleteKey[index]}
                  removedScheduleIndex={removedScheduleIndex}
                />
              );
            })}
          <Box className={classes.divActions}>
            <Button
              className={classes.button}
              onClick={handleAddSchedule}
              disabled={
                schedules.length === process.env.REACT_APP_MAX_SCHEDULES
              }
              variant="contained" //outline
              startIcon={<AddCircleOutlineIcon />}
              size="small"
            >
              <Typography variant="body2">添加</Typography>
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              // color="success"
              size="small"
              onClick={handleSubmit}
            >
              <Typography variant="body2">查 询</Typography>
            </Button>
          </Box>
          <Box className={classes.divHistory}>
            <SearchHistory searchHis={searchHis} />
          </Box>
        </Box>
        <Box className={classes.divResults}>
          <WeatherResult weatherRes={weatherRes} />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Weather;
