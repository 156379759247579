import { makeStyles } from "@material-ui/core/styles";

/*** search history items ****/
const searchHisStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    gap: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: "1", // Allow it to take the remaining space of its parent container
    minWidth: 0, // let the container completely grow to fit its content !!!important
  },
  itemBox: {
    boxSizing: "border-box",
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
    },
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    verticalAlign: "top",
    maxWidth: ({ cnt }) => {
      const totalSpacingInPixels = theme.spacing(1) * (cnt - 1); // Total spacing for all gaps
      const totalSpacingInPercentage =
        (totalSpacingInPixels / window.innerWidth) * 100; // Convert to percentage based on the viewport width
      const portion = (100 - totalSpacingInPercentage) / cnt; // portion of the itemBox width
      return `${Math.floor(portion)}%`;
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  listItem: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis", // adds ... to indicate text is truncated
    whiteSpace: "nowrap", // ensures text doesn't wrap to the next line
    /** 多行显示 
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    **/
    "&:hover": {
      color: theme.palette.text.primary,
    },
    // fontSize: "22px",
    // fontWeight: 400,
    // lineHeight: 1.5,
    // letterSpacing: "0.01em",
  },
}));

/*** search history page ****/
// const historyStyles = makeStyles((theme) => ({
//   root: {
//     marginLeft: "5rem",
//     padding: theme.spacing(4),
//     display: "flex",
//     flexDirection: "column",
//     gap: theme.spacing(2),
//     textAlign: "left",
//   },
//   container: {
//     textAlign: "left",
//     width: "65%",
//     paddingTop: "2vh",
//   },
//   title: {
//     color: theme.palette.primary.main,
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
//   divider: {
//     textAlign: "left",
//   },
//   subtitle: {
//     color: theme.palette.primary.main,
//   },
//   itemBox: {
//     marginBottom: "5px",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "left",
//     "&:hover": {
//       backgroundColor: theme.palette.secondary.main,
//       color: theme.palette.common.white,
//       cursor: "pointer",
//     },
//   },
//   listItem: {
//     fontSize: "22px",
//     fontWeight: 400,
//     lineHeight: 1.5,
//     letterSpacing: "0.01em",
//   },
// }));

export default searchHisStyles;
