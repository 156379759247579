import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core"; //List, ListItem,

// import { useNavigate } from "react-router-dom";
// import Divider from "@mui/material/Divider";

import { useSearchHistory } from "../utils/SearchHistoryContext";
import searchHisStyles from "../styles/History.styles";

SearchHistory.propTypes = {
  searchHis: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string.isRequired,
      schedules: PropTypes.arrayOf(
        PropTypes.shape({
          location: PropTypes.string.isRequired,
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
        }),
      ).isRequired,
      create_time: PropTypes.string.isRequired,
      update_time: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

function SearchHistory({ searchHis }) {
  const cnt = searchHis.length;
  const classes = searchHisStyles({ cnt });
  const { setSelectedHistoryItem } = useSearchHistory();

  function handleClick(historyItem) {
    // via context update
    setSelectedHistoryItem(historyItem);
  }

  return (
    <Box className={classes.root}>
      <Typography variant="body2" className={classes.title}>
        最近查询
      </Typography>
      <Box className={classes.container}>
        {searchHis.length === 0 && (
          <Box textAlign={"center"}>
            <Typography variant="body2" color="textSecondary">
              无记录
            </Typography>
          </Box>
        )}
        {searchHis.map((record, index) => (
          <Box
            key={index}
            className={classes.itemBox}
            onClick={() => handleClick(record)}
          >
            <Typography variant="body2" className={classes.listItem}>
              {record.schedules
                .map(
                  (schedule) =>
                    `${schedule.location} ${schedule.start.slice(
                      5,
                    )}至${schedule.end.slice(5)}`,
                )
                .join(", ")}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default SearchHistory;
