const copyObj = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    // If obj is not an object or is null, return it as is
    return obj;
  }

  const newObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      // Recursively copy nested objects and arrays
      newObj[key] = copyObj(obj[key]);
    }
  }

  return newObj;
};

//   helper function to output yyyy-mm-dd date format regardless of browser language
const getDateStr = (date) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return date
    .toLocaleDateString("sv-SE", options)
    .split("/")
    .map((num) => num.padStart(2, "0"))
    .join("-");
};

const getLocalDateStr = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Function to compare two arrays of schedules for equality by value instead of reference
const areSchedulesEqual = (schedule1, schedule2) => {
  if (schedule1.length !== schedule2.length) {
    // console.log("false1");
    return false;
  }
  // Sort schedules by start date to ensure consistent comparison
  const sortedSchedule1 = [...schedule1].sort((a, b) =>
    a.start.localeCompare(b.start),
  );
  const sortedSchedule2 = [...schedule2].sort((a, b) =>
    a.start.localeCompare(b.start),
  );
  // console.log("sortedSchedule1", sortedSchedule1);
  // console.log("sortedSchedule2", sortedSchedule2);
  // Compare each schedule in the array
  for (let i = 0; i < sortedSchedule1.length; i++) {
    const scheduleA = sortedSchedule1[i];
    const scheduleB = sortedSchedule2[i];
    // Compare each property of the schedules
    if (
      scheduleA.location !== scheduleB.location ||
      scheduleA.start !== scheduleB.start ||
      scheduleA.end !== scheduleB.end
    ) {
      // console.log("false2");
      return false;
    }
  }
  // console.log("true");
  return true;
};

// account for vertical scroll bar width in the layout
const getScrollbarWidth = () => {
  const width = window.innerWidth - document.documentElement.clientWidth;
  // Check if the width is unusually large
  return width > 20 ? 0 : width; // Assume a max scrollbar width of 20px, else use 0
};

// debounce the search function to avoid too many API calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

export {
  copyObj,
  getDateStr,
  getLocalDateStr,
  areSchedulesEqual,
  getScrollbarWidth,
  debounce,
};
