import React from "react";

import logo from "../images/logo.png";
import logoStyles from "../styles/Logo.styles";

function Logo() {
  const classes = logoStyles();

  return (
    <div className={classes.logoContainer}>
      <img src={logo} alt="Logo" className={classes.logoImage} />
    </div>
  );
}

export default Logo;
