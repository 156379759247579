import React, { useState, useRef, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

import graphQLFetch from "../utils/graphQLFetch";
import { debounce } from "../utils/helpers";
import queryStyles from "../styles/Query.styles";

LocationSearch.propTypes = {
  index: PropTypes.number.isRequired,
  schedule: PropTypes.shape({
    location: PropTypes.string,
    locationID: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    lon: PropTypes.number,
    lat: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  onUpdateSchedule: PropTypes.func.isRequired,
  onUpdateScheduleRef: PropTypes.func.isRequired,
  error: PropTypes.shape({
    location: PropTypes.string.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  autocompleteKey: PropTypes.number,
  removedScheduleIndex: PropTypes.number,
};

function LocationSearch({
  index,
  schedule,
  onUpdateSchedule,
  onUpdateScheduleRef,
  error,
  autocompleteKey,
  removedScheduleIndex,
}) {
  const classes = queryStyles();
  const [searchTerm, setSearchTerm] = useState(""); // user input field
  const [searchResults, setSearchResults] = useState([]); // autocomplete options
  const [searchError, setSearchError] = useState(false); // if the input field is valid or not
  const [searchHelperText, setSearchHelperText] = useState(""); // error message shown for form validation
  const autocompleteRef = useRef(null); // as reference to the input field

  useEffect(() => {
    onUpdateScheduleRef("location", autocompleteRef);
  }, []);

  // update the error message
  useEffect(() => {
    if (error) {
      setSearchError(!!error.location);
      setSearchHelperText(error.location || "");
    }
  }, [error]);

  // update the user input field if option is selected
  useEffect(() => {
    if (schedule.lon) {
      setSearchTerm(generateLabel(schedule));
    } else {
      setSearchTerm("");
    }
  }, [schedule.lon]); //schedule.lon

  // clear the autocomplete options if previous schedule is updated, for re-rendering
  useEffect(() => {
    if (removedScheduleIndex !== -1 && index >= removedScheduleIndex) {
      setSearchResults([]);
    }
  }, [removedScheduleIndex]);

  // generate label to be shown as autocomplete options
  function generateLabel(object) {
    const state = object.state ? `${object.state}, ` : "";
    const name = object.name ? object.name : object.location;
    const label = `${name}, ${state}${object.country}`;
    //with geolocation: (${object.lat.toFixed(1)}\u00B0, ${object.lon.toFixed(1)}\u00B0)
    return label;
  }

  const debouncedHandleSearch = useCallback(
    debounce((value) => handleSearch(value), 300),
    [],
  );

  // call graphQL API to get autocomplete options given a keyword
  async function handleSearch(inputValue) {
    const lng = "zh-hans"; //by default
    const source = "hefeng";
    const query = `
            query locations($kw: String!, $lng: String, $source: String!,) {
              listLocations(kw: $kw, lng: $lng, source:$source) {
                  location
                  locationID
                  state
                  country
                  lon
                  lat
              }
          }`;
    // if inputValue is not empty, use it as the keyword; otherwise use state searchTerm by default
    let kw;
    if (inputValue) {
      kw = inputValue;
    } else {
      kw = searchTerm;
    }
    kw = kw.split("(")[0];
    kw = kw.trim();
    if (!kw) {
      return;
    }
    const data = await graphQLFetch(query, { kw, lng, source });

    // if the keyword is valid and results are returned
    if (data && data.listLocations.length > 0) {
      setSearchError(false);
      setSearchHelperText("");
      const res = data.listLocations.map((location) => {
        return {
          ...location,
          // text: `${location.name}, ${state}${location.country}`,
          label: generateLabel(location),
        };
      });
      setSearchResults(res);
      autocompleteRef.current.focus();
    } else {
      // update error message
      setSearchResults([]);
      setSearchError(true);
      setSearchHelperText("无相关结果"); //No results found
    }
  }
  // if an option is selected, update the schedules state
  const handleChange = (event, option, reason) => {
    if (reason === "selectOption" || reason === "blur") {
      setSearchError(false);
      setSearchHelperText("");
      setSearchTerm(option.label);
      const newSchedule = schedule;
      newSchedule.location = option.location;
      newSchedule.locationID = option.locationID;
      newSchedule.state = option.state;
      newSchedule.country = option.country;
      newSchedule.lon = option.lon;
      newSchedule.lat = option.lat;
      onUpdateSchedule(newSchedule);
    }
  };

  // handle user input text change
  const handleInputChange = (event, value, reason) => {
    if (reason === "clear" || (reason === "input" && value === "")) {
      console.log("hendleinputchange clear options");
      setSearchTerm("");
      setSearchResults([]);
      setSearchError(false);
      setSearchHelperText("");
      onUpdateSchedule({
        location: "",
        locationID: "",
        state: "",
        country: "",
        lon: null,
        lat: null,
        start: "",
        end: "",
      });
    } else if (reason === "input") {
      // if it is real user input
      setSearchTerm(value);
      debouncedHandleSearch(value);
      // setAutocompleteKey((prevKey) => prevKey + 1);
      // autocompleteRef.current.focus();
    }
  };

  // enable enter key press to trigger location search for autocomplete options
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchResults.length === 0) {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <Box className={classes.divLocation}>
      <Autocomplete
        size="small"
        className={classes.divAutocomplete}
        // freeSolo
        autoHighlight={true}
        autoSelect={true}
        // openOnFocus
        options={searchResults}
        getOptionLabel={(option) => {
          // if (option.hasOwnProperty("name")) {
          if (Object.prototype.hasOwnProperty.call(option, "location")) {
            return option.label;
          } else {
            return "";
          }
        }}
        key={autocompleteKey}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        inputValue={searchTerm}
        clearText="清除"
        noOptionsText="无相关结果"
        // filterOptions={(options, state) => options}
        filterOptions={(options) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="输入地点"
            inputRef={autocompleteRef}
            variant="standard"
            // label="Location"
            error={searchError}
            helperText={searchHelperText}
          />
        )}
      />
      {/* <IconButton onClick={handleSearch} size="small">
        <SearchIcon fontSize="inherit" />
      </IconButton> */}
    </Box>
  );
}

export default LocationSearch;
