import { makeStyles } from "@material-ui/core/styles";

const weaResultStyles = makeStyles((theme) => ({
  "@media screen and (max-width: 750px)": {
    grid: {
      justifyContent: "flex-start !important",
      flexWrap: "nowrap !important",
      overflowX: "auto", // Enable horizontal scrolling
      marginBottom: "0.5rem",
    },
    card: {
      width: "6rem",
      minWidth: "6rem !important", //7.5
      margin: "0 !important", // 0.5rem
      boxSizing: "border-box",
    },
  },

  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  grid: {
    justifyContent: "center",
    minWidth: 0, //80vh
    flex: 1,
  },
  card: {
    width: "7.5rem", //7.5
    margin: "0.25rem", // 0.25rem
  },
  cardContent: {
    padding: "0.5rem !important", //14px
  },
  cardContentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  divChart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "4.5rem", //80px
    maxWidth: "3.125rem", //50px
  },
  divSupInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "1.375rem", //22px
    // maxWidth: "3.75rem", //60px
    marginTop: "0.25rem", //4px
  },
  divPrep: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.2rem",
  },
  divCloud: {
    display: "flex",
    flexDirection: "row",
  },
  divSun: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "1.375rem", //22px
    maxWidth: "3.75rem", //60px
    marginTop: "0.25rem", //4px
  },
  chart: {
    width: "2.5rem !important", //40px
    height: "4.5rem !important", //80px
  },
  weatherImage: {
    width: "2.5rem", //40px
    height: "2.5rem", //40px
    marginTop: "0.125rem", //2px
  },
  iconImage: {
    width: "0.94rem", //15px
    height: "0.94rem", //15px
    marginLeft: "0.125rem", //2px
  },
  textSubtitle: {
    color: theme.palette.primary.main, //#1A5F7A
  },
  textCity: {
    color: theme.palette.primary.main, //#1A5F7A
    marginTop: "0.25rem !important",
  },
}));

export default weaResultStyles;
